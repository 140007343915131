<template>
  <div class="container">
    <b-row class="top-row noselect" @click.prevent="show_form = !show_form">

      <b-col cols="11">
        <v-list-item>
          <v-list-item-icon>
            <v-icon size="42">mdi-credit-card</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="fixed-font">{{$t('PAYMENT.PAYMENTS')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </b-col>
      <b-col cols="1">
        <div class='justify-content-end d-flex'>
          <a v-if="show_form" href="#"
            class="mt-2">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg style="fill: #919191" src="/assets/svg/angle-up-solid.svg"></inline-svg>
            </span>
          </a>

          <a v-if="!show_form" href="#"
            class="mt-2" >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg  src="/assets/svg/angle-down-solid.svg"></inline-svg>
            </span>
          </a>

        </div>
      </b-col>
    </b-row>

    <div v-if="show_form">

      <b-table
        id="member-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :items="items"
        head-variant="light"
        ref="memberTable"
      >
        <template #cell(receipt)="row">
          <a href="" @click.prevent="download_receipt(row.item.shop_order_id)">{{ $t('PAYMENT.DOWNLOAD') }}</a>
        </template>

      </b-table>

      <p v-if="items.length === 0">{{$t('MEMBER.NO_ORDERS_TABLE')}}</p>


    </div>

    <hr/>
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { is_mobile } from '@/core/services/utils';

export default {

  name: 'MemberTableOrders',

  props: ['member','items','company','expanded'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {},

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {

    async download_receipt(shop_order_id) {
      try {
        const res = await axios.get(`/shop_order/my_receipt/${shop_order_id}`);

        if (res.status === 200) {
          const file = res.data;
          downloadWithAxios(get_base_url() + `/dlfile/${file.linkstr}`, file.name);
          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_DOWNLOAD'));
    },

    async save_data() {

      this.$emit('save_data', this.member);

    }

  },

  mounted() {
    this.show_form = this.expanded;

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      fields: [
        {
          key: 'shop_order_items',
          label: this.$t('PAYMENT.ARTICLE'),
          mobile: true,
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {

            return item.shop_order_items.map((item) => {
              return item.shop_item.name;
            }).join(', ');
          }
        },

        {
          key: 'type',
          label: this.$t('PAYMENT.TYPE'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            switch (item.type) {
              case 'UNKNOWN': {
                return this.$t('SHOP_ITEM.TYPE.UNKNOWN');
              }
              case 'REGISTER': {
                return this.$t('SHOP_ITEM.TYPE.REGISTER');
              }
              case 'RENEW': {
                return this.$t('SHOP_ITEM.TYPE.RENEW');
              }
              case 'TICKET': {
                return this.$t('SHOP_ITEM.TYPE.TICKET');
              }
              case 'COMPANY': {
                return this.$t('SHOP_ITEM.TYPE.COMPANY');
              }
              case 'MEMBER': {
                return this.$t('SHOP_ITEM.TYPE.MEMBER');
              }
              case 'REDEEM_CODE': {
                return this.$t('SHOP_ITEM.TYPE.REDEEM_CODE');
              }
              case 'STRIPE_INVOICE': {
                return this.$t('SHOP_ITEM.TYPE.STRIPE_INVOICE');
              }
            }
          }
        },

        {
          key: 'created_at',
          label: this.$t('PAYMENT.CREATED_AT'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          key: 'amount',
          label: this.$t('PAYMENT.AMOUNT'),
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          key: 'is_paid',
          label: this.$t('MEMBERSHIP.IS_PAID'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.is_paid ? this.$t('COMMON.YES') : this.$t('COMMON.NO');
          }
        },

        {
          key: 'receipt',
          label: this.$t('PAYMENT.RECEIPT'),
          mobile: false,
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

      ],

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
