<template>
  <div class="px-5" id="page-event-details">

    <b-row class="mt-4">
      <b-col lg="12" v-if="event.type === 'PHY'">
        <google-map
          :center="{ lat: event.google_maps.lat, lng: event.google_maps.lng }"
          :zoom="16"
          style="height: 256px"
          id="map"
          ref="Map"
          class="shadow-sm p-2 mb-5 rounded"
        >
          <google-map-marker
            :position="{ lat: event.google_maps.lat, lng: event.google_maps.lng }"
          ></google-map-marker>
        </google-map>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col lg="12">
        <b-card :title="$t('PAGES.EVENTS.DETAILS')" class="mb-2 shadow-sm" hide-footer>
          <div class="row">
            <div class="col-8">
              <div class="title-info">
                <h4>{{$t('PAGES.EVENTS.DATE_TIME')}}</h4>
                <p>{{ from_formatted }}</p>
              </div>
              <div class="title-info" v-if="event.use_deadline">
                <div v-if="deadlinePassed">

                  <h4 style="color: red;">{{$t('PAGES.EVENTS.SIGNUP_DATE_EXPIRED')}}</h4>

                </div>
                <div v-else>
                  <h4>{{$t('PAGES.EVENTS.LAST_SIGNUP_DATETIME')}}</h4>
                  <p>{{ event.deadline_datetime }}</p>
                </div>

              </div>
              <div class="title-info">
                <h4>{{$t('PAGES.EVENTS.LOCATION')}}</h4>
                <p v-if="event.location_descr && event.location_descr.length > 4">
                  {{ event.location_descr }}
                </p>
                <p v-if="event.type === 'PHY'">
                  {{ event.address }}, {{ event.zipcode }} {{ event.postaladdress }}
                </p>
                <p v-if="event.type === 'ONL'">{{$t('PAGES.EVENTS.ONLINE')}}</p>
              </div>
            </div>
            <div class="col-4">
              <div class="title-info">
                <p
                  v-if="iAmSignedUp"
                  id="i-am-signedup-label"
                  style="display: none; color: #00c357; font-weight: bold"
                >
                  {{$t('PAGES.EVENTS.REGISTERED')}}
                </p>

                <p
                  v-if="isFull"
                  id="i-am-signedup-label"
                  style="display: none; color: #00c357; font-weight: bold"
                >
                  {{$t('PAGES.EVENTS.EVENT_FULL')}}
                </p>

                <p
                  v-if="!isFull"
                  id="i-am-signedup-label"
                  style="display: none; color: #00c357; font-weight: bold"
                >
                  {{$t('PAGES.EVENTS.EVENT_NOT_FULL')}}
                </p>
              </div>

            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4" v-if="isExistsLink()">
      <b-col lg="12">
        <b-card :title="$t('PAGES.EVENTS.LINKS')" class="mb-2 shadow-sm" hide-footer>
          <a
            :href="event.facebook_url"
            :title="event.facebook_url"
            class="d-flex mb-3"
            v-if="
              event.facebook_url !== undefined &&
                event.facebook_url != '' &&
                this.event.facebook_url != 'https://'
            "
          >
            <i class="fab fa-facebook-square mr-5" style="font-size:36px"></i>
            <span class="align-self-center">{{$t('PAGES.EVENTS.FACEBOOK_INFO')}}</span>
          </a>
          <a
            :href="event.extern_url"
            :title="event.extern_url"
            class="d-flex"
            v-if="
              event.extern_url !== undefined &&
                event.extern_url != '' &&
                this.event.extern_url != 'https://'
            "
          >
            <!-- <i class="fab fa-internet-explorer pr-2 " style="font-size:36px" :title="event.extern_url"></i> -->
            <i class="fas fa-globe pr-1 mr-3" style="font-size:36px" :title="event.extern_url"></i>
            <span class="align-self-center">
              {{$t('PAGES.EVENTS.VISIT_WEBPAGE')}}
            </span>
          </a>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col lg="12">
        <b-card :title="event.name" class="mb-2 shadow-sm" hide-footer>
          <div v-html="event.descr_html"></div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col lg="12">
        <div class="d-flex justify-content-end">
          <a href="#" class="btn btn-sm mx-3 font-weight-bold" @click="gotoEvent()">
            <!-- <span class="svg-icon svg-icon-md svg-icon-primary"> -->
            <!-- Läs mer -->
            <!-- </span> -->
          </a>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>


.contain-info {
  padding: 8px;
}

.contain-tickets {
  border: 1px solid #EBEDF3;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

img {
  width: 100%;
  height: auto;
}

.event_style {
  background-color: grey;
}

.sum-box {
  border: 1px solid #dedede;
  text-align: center;
  padding: 8px;
}

</style>

<script>
import messageParent from '@/core/services/messageParent';
import axios from 'axios';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import EventLogin from '@/view/pages/members/public/EventLogin.vue';
import TicketSelectRow from '@/view/pages/members/public/TicketSelectRow.vue';
import TicketCartSummaryRow from '@/view/pages/members/public/TicketCartSummaryRow.vue';
import RegisterMemberNoAccount from '@/view/pages/members/auth/RegisterMemberNoAccount.vue';
import MemberLoggedInCard from '@/view/pages/members/public/MemberLoggedInCard.vue'
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'page-event-details',
  components: {
    EventLogin,
    RegisterMemberNoAccount,
    TicketSelectRow,
    TicketCartSummaryRow,
    MemberLoggedInCard
  },
  props: ['event_id', 'member_id'],
  computed: {
    deadlinePassed() {
      return this.event.use_deadline && dayjs(this.event.deadline_datetime) < dayjs();
    },
    ...mapGetters(['isMemAuthenticated'])
  },

  watch: {
    'event_id'(newValue) {
      this.loadEvent();
    }
  },
  mixins: [ toasts ],

  mounted() {
    this.loadEvent();
  },
  data() {
    return {
      maxExceeded: false,
      attendeeData: {
        form: null,
        is_member: false
      },
      isAcceptTerms: false,
      isTicketStepOK: false,
      numTickets: 0,
      sumTickets: 0,
      tickets: [],
      testEventShopItem: {
        id: 0,
        shop_item: {
          name: 'Detta är ett test',
          price_sek: 100
        }
      },
      event: {
        google_maps: { lat: 0, lng: 0 },
        use_deadline: false,
        deadline_datetime: null
      },
      from_formatted: '',
      price: '',
      count_members: 0,
      iAmSignedUp: false,
      isFull: false
    };
  },
  methods: {

    async loadData() {
      this.loadEvent();
    },

    loadEvent() {

      axios
        .get(`/events/${this.event_id}`)
        .then(res => {
          this.event = res.data;

          if (this.event.from_datetime === '' || this.event.from_datetime === null) {
            this.event.from_datetime = '2020-12-01T20:00:00';
          }

          if (this.event.to_datetime === '' || this.event.to_datetime === null) {
            this.event.to_datetime = '2099-12-31T20:00:00';
          }

          var mom =
            dayjs(this.event.from_datetime).format('YYYY-MM-DD HH:mm') +
            ' till ' +
            dayjs(this.event.to_datetime).format('YYYY-MM-DD HH:mm');

          if (this.event.cost === 0) {
            this.price = 'Gratis';
          } else {
            this.price = this.event.cost; // todo currency
          }

          this.from_formatted = mom;

          this.isFull = !(this.event.num_signups < this.event.num_seats);

          setTimeout(function() {
            messageParent(JSON.stringify({ height: document.body.scrollHeight }));
          }, 300);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.EVENT_NOT_FOUND'));
          //this.$router.push('/404');
        });
    },

    isExistsLink() {
      return (
        (this.event.extern_url !== undefined &&
          this.event.extern_url != '' &&
          this.event.extern_url != 'https://') ||
        (this.event.facebook_url !== undefined &&
          this.event.facebook_url != '' &&
          this.event.facebook_url != 'https://')
      );
    },

    gotoEvent() {
      this.$router.push(`/event/${this.event.event_id}`);
    },

  }
};
</script>
