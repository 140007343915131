<template>
  <div class="container">
    <b-modal class="px-5 text-center" ref="showTicket">
      <b-row class="justify-content-center text-center">
        <b-col>
          <img class="text-center mx-auto w-100"  :src="QR_URL" />
        </b-col>
      </b-row>

    </b-modal>

    
    <b-modal class="px-5" ref="showEvent" hide-footer>
      <MemberSummaryEvent
        :event_id="selected_event_id"
        :member_id="member.member_id"
      />

    </b-modal>

    <b-row class="top-row noselect" @click.prevent="show_form = !show_form">

      <b-col cols="11">

        <v-list-item>
          <v-list-item-icon>
            <v-icon size="42">mdi-calendar-month</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="fixed-font">{{$t('MEMBER.ACTIVE_EVENTS')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </b-col>
      <b-col cols="1">
        <div class='justify-content-end d-flex'>
          <a v-if="show_form" href="#"
            class="mt-2">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg style="fill: #919191" src="/assets/svg/angle-up-solid.svg"></inline-svg>
            </span>
          </a>

          <a v-if="!show_form" href="#"
            class="mt-2" >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg  src="/assets/svg/angle-down-solid.svg"></inline-svg>
            </span>
          </a>

        </div>
      </b-col>
    </b-row>

    <div v-if="show_form">

      <b-table
        id="member-event-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :items="items"
        head-variant="light"
        ref="memberEventTable"
      >

      <template #cell(actions)="row">
        <div class="justify-content-end d-flex">
          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="show_event(row.item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg  src="/assets/svg/Write.svg"></inline-svg>
          </span>
          </a>

          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="show_ticked_clicked(row.item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg  src="/assets/svg/ticket-solid.svg"></inline-svg>
          </span>
          </a>

        </div>
      </template>

      </b-table>

      <p v-if="items.length === 0">{{$t('MEMBER.NO_EVENTS_FOUND')}}</p>


    </div>

    <hr/>
  </div>

</template>

<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import MemberSummaryEvent from '@/view/pages/members/loggedin/MemberSummaryEvent.vue';

export default {

  name: 'MemberTableEvents',

  props: ['member','items','company','expanded'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {
    MemberSummaryEvent
  },

  watch: {

  },

  computed: {

  },

  methods: {
    show_event(item) {
      this.selected_event_id = item.event_id;

      this.$nextTick(()=>{
        this.$refs['showEvent'].show();
      });
    },

    async save_data() {

      this.$emit('save_data', this.member);

    },

    show_ticked_clicked(item) {
      this.showQR_CODE(item);
    },

    showQR_CODE(event) {
      console.log('event', event)
      this.$refs['showTicket'].show();

      if (event.qr_filename.indexOf('localhost') >= 0) {
        this.QR_URL = 'http://localhost:3020/data/' + event.qr_filename.split('/')[4];
      }
      else {
        this.QR_URL = event.qr_filename;
      }

    },

    showEventDetail(event) {
      this.selected_event_id = event.event.event_id;
      this.event_selected = event.event.event_id;
      this.$refs['showEvent'].show();
    },

    async sendTicketClicked(member_event_id) {

      try {
        const res = await axios.post(`/member_event/send_ticket/${member_event_id}`, {})

        if (res.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), 'Biljett har skickats till din e-post');
        }

      }
      catch (err) {
        console.error(err);
      }

    }

  },

  mounted() {
    this.show_form = this.expanded;
    let baseUrl = axios.defaults.baseURL;
    this.baseUrl = baseUrl.substring(0, baseUrl.indexOf('/', 8));
  },

  data() {
    return {

      selected_event_id: null,


      event_selected: null,

      QR_URL: '',

      baseUrl: '',

      fields: [
        {
          key: 'event.name',
          label: this.$t('MEMBER.EVENT'),
          sortable: false,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          key: 'event.from_datetime',
          label: this.$t('MEMBER.FROM'),
          sortable: false,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          key: 'event.to_datetime',
          label: this.$t('MEMBER.TO'),
          sortable: false,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          key: 'actions',
          label: this.$t('PAGES.EVENTS.TICKET'),
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'text-right'
        },
      ],

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>

