<template>
  <div class="container">
    <b-row class="top-row noselect" @click.prevent="show_form = !show_form">

      <b-col cols="11">
        <v-list-item>
          <v-list-item-icon>
            <v-icon size="42">mdi-multimedia</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="fixed-font">{{$t('MEMBER.ATTRIBUTES')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </b-col>
      <b-col cols="1">
        <div class='justify-content-end d-flex'>
          <a v-if="show_form" href="#"
            class="mt-2">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg style="fill: #919191" src="/assets/svg/angle-up-solid.svg"></inline-svg>
            </span>
          </a>

          <a v-if="!show_form" href="#"
            class="mt-2" >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg  src="/assets/svg/angle-down-solid.svg"></inline-svg>
            </span>
          </a>

        </div>
      </b-col>
    </b-row>

    <div v-if="show_form && groups" class="container flex-container">
      
      <MemberSectionAttributesRow 
        v-for="group in groups"
        :key="group.group_id"
        :group="group"
      />
    </div>

    <hr/>
  </div>

</template>

<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import MemberSectionAttributesRow from '@/view/pages/members/loggedin/new_member_profile/MemberSectionAttributesRow.vue';


export default {

  name: 'MemberSectionAttributes',

  props: ['member','items','company','expanded'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {
    MemberSectionAttributesRow
  },

  watch: {
    groups: {
      handler(newVal) {
        newVal.forEach((group) => {
          this.$watch(
            () => group.selectedPropId,
            (newPropId) => {
              this.onPropertyChange(group);
            }
          );
        });
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {
    async save_data() {

      this.$emit('save_data', this.member);

    },

    onPropertyChange(group) {
      console.log(
        `Group ${group.name} selected property ID: ${group.selectedPropId}`
      );
      // You can perform additional actions here
    },

    async fetch_profile_attributes() {
      try {
        const res = await axios.get(`/property_group/profile`);

        if (res.status === 200) {
          this.groups = res.data;
        }
      }
      catch (err) {
        console.error('fetch_profile_attributes', err);
      }
    }

  },

  async mounted() {
    this.show_form = this.expanded;

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }

    await this.fetch_profile_attributes();
  },

  data() {
    return {

      show_form: true,

      groups: []

    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";

</style>
